import React from 'react'
import Layout from '../layouts/simple.js'
import { Helmet } from 'react-helmet'
import '../styles/solutions.scss'
import Healthcare from '../images/healthcare-furniture.jpg'
import Education from '../images/education.jpg'
import Government from '../images/government-seating.jpg'
import Business from '../images/small-business.jpg'
import Home from '../images/home-office.jpg'
import CTA from '../components/cta.js'

const Solutions = () => {
    return (
        <div className="page">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Dundas Interiors - Office Furniture For Every Setting</title>
            <link rel="canonical" href="dundasinteriors.com" />
        </Helmet>
        <Layout>
            <section className="hero solutions-page">
                <div className="hero-background">
                    <div className="container">
                        <div className="hero-content">
                            <h1>We Work With The World's Top Brands</h1>
                            <h3>For 100 years we've made problem-solving designs that are as beautiful as they are useful.</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-60">
                <div className="container column-four-six">
                    <div className="center-vertical">
                        <div>
                            <h2>Healthcare and Therapeutic Settings</h2>
                            <h3>We work with hospitals and clinics to design everything from consultation rooms and office space to waiting rooms and check-in stations. We understand and help you comply with privacy and sanitation standards.</h3>
                        </div>
                    </div>
                    <div className="image-container"><img src={Healthcare} alt="a patient chair and bed"></img></div>
                </div>
            </section>
            <section className="">
                <div className="container column-six-four">
                    <div className="image-container"><img src={Education} alt="classroom"></img></div>
                    <div className="center-vertical">
                        <div>
                            <h2>Education, Campus and Research Settings</h2>
                            <h3>We’ve furnished student union halls at the university and individual office space on all kinds of campuses. Furnishings, with durability and style are our specialty.</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-60">
                <div className="container column-four-six">
                    <div className="center-vertical">
                        <div>
                            <h2>Government Agencies and Public Settings</h2>
                            <h3>Whether you are outfitting workstations in a high traffic public building--or conference rooms and offices, we have the durable designs you are looking for.</h3>
                        </div>
                    </div>
                    <div className="image-container"><img src={Government} alt="a group of desks and chairs"></img></div>
                </div>
            </section>
            <section className="">
                <div className="container column-six-four">
                    <div className="image-container"><img src={Business} alt="a desk with chairs"></img></div>
                    <div className="center-vertical">
                        <div>
                            <h2>Business, Corporate and Managerial Settings</h2>
                            <h3>Height-adjustable desks and modern office chairs are central to our services, and we offer much more: workspace layout, integrated storage solutions, customer contact stations and more.</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-60">
                <div className="container column-four-six">
                    <div className="center-vertical">
                        <div>
                            <h2>Home Office and Non-Traditional Work Settings</h2>
                            <h3>When your home office is central to your career, you need the quality and comfortable style we’ve been delivering for a century. Adjustable desks and superior seating are our specialty.</h3>
                        </div>
                    </div>
                    <div className="image-container"><img src={Home} alt="computer desk and chair"></img></div>
                </div>
            </section>
            <CTA />
        </Layout>
        </div>
    )
    }

    export default Solutions